class Draggon {
  constructor(elem) {
    this.elem = document.querySelector(elem);
    this.elemSelector = elem;
    this.dragObject = {};
    this.coords = {};
    this.dragObject.currentX = 0;
    this.windowWidth = window.innerWidth;
    this.startx = 0;
    this.starty = 0;
    this.diffx = 0;
    this.diffy = 0;
    this.drag = false;

    this.navigation = {
      leftArrowAttribute: '[data-draggon-nav-left]',
      rightArrowAttribute: '[data-draggon-nav-right]',
    };
  }

  bindEvents() {
    document.addEventListener('mousedown', this.onMouseDown.bind(this));
    document.addEventListener('mouseup', this.onMouseUp.bind(this));
    document.addEventListener('mousemove', this.onMouseMove.bind(this));
    this.bindArrows(this);
  }

  unbindEvents() {
    document.removeEventListener('mousedown', this.onMouseDown.bind(this));
    document.removeEventListener('mouseup', this.onMouseUp.bind(this));
    document.removeEventListener('mousemove', this.onMouseMove.bind(this));
  }

  onMouseDown(e) {
    if (!e.target.closest(this.elemSelector)) {
      return;
    }
    if (e.which !== 1) {
      return;
    }
    this.startx = e.clientX + this.elem.scrollLeft;
    this.starty = e.clientY + this.elem.scrollTop;
    this.diffx = 0;
    this.diffy = 0;
    this.drag = true;
  }

  onMouseUp(e) {
    if (!e) { e = window.event; }
    if (!this.elem) {
      return;
    }
    if (!this.drag) {
      return;
    }
    let start = 1;
    const animate = () => {
      const step = Math.sin(start);
      if (step <= 0) {
        window.cancelAnimationFrame(animate);
      } else {
        this.elem.scrollLeft += this.diffx * step;
        this.elem.scrollTop += this.diffy * step;
        start -= 0.02;
        window.requestAnimationFrame(animate);
      }
    };
    animate();
    this.drag = false;
  }

  onMouseMove(e) {
    if (this.drag === true) {
      if (!e) { e = window.event; }
      this.diffx = (this.startx - (e.clientX + this.elem.scrollLeft));
      this.diffy = (this.starty - (e.clientY + this.elem.scrollTop));
      this.elem.scrollLeft += this.diffx;
      this.elem.scrollTop += this.diffy;
      this.elem.querySelectorAll('a').forEach((link) => {
        link.style.pointerEvents = 'none';
      });
    } else if (this.elem) {
      this.elem.querySelectorAll('a').forEach((link) => {
        link.style.pointerEvents = 'auto';
      });
    }
  }

  bindArrows() {
    if (!this.elem) {
      return;
    }

    const parentWrapper = this.elem.parentNode;
    const leftArrow = parentWrapper.querySelector(this.navigation.leftArrowAttribute);
    const rightArrow = parentWrapper.querySelector(this.navigation.rightArrowAttribute);

    // setTimeout(() => {
      if (leftArrow && rightArrow) {
        leftArrow.addEventListener('click', () => {
          this.elem.scrollBy({
            left: -250,
            behavior: 'smooth'
          })
        });
        rightArrow.addEventListener('click', () => {
          this.elem.scrollBy({
            left: 250,
            behavior: 'smooth'
          })
        });
      }
    // }, 1500);
  }
}

export default Draggon;